import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Flag = styled(Chip, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop =>
    prop !== "variant" &&
    prop !== "color" &&
    prop !== "size" /* && prop !== "customProp"  */,
  name: "Flag",
  overridesResolver: (props, styles) => [
    //props.disabled && styles.disabled,
    //props.alignment == "centered" && styles.centered,
  ],
})(({ theme }) => ({
  borderRadius: "5px",
  height: "unset",
  fontSize: "16px",
  textTransform: "uppercase",
  fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
  "& .MuiChip-label": {
    padding: "0px 5px",
  },
}));
