import toolsIcon from "../assets/icons/tools.svg";
import communityIcon from "../assets/icons/community.svg";
import searchIcon from "../assets/icons/search.svg";
import thumbIcon from "../assets/icons/thumb.svg";
const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

export const TOOLSICON = toolsIcon;
export const ROADRUNNERICON = `${cloudFrontUrl}/Images/road-runner.svg`;
export const COMMUNITYICON = communityIcon;
export const SEARCHICON = searchIcon;
export const THUMBICON = thumbIcon;
export const LOGOICON = `${cloudFrontUrl}/Images/logo.svg`;
export const DASHBOARDIMG = `${cloudFrontUrl}/Images/HomePageHero-3-30-22.png`;

export const COMMUNITYLOGO = `${cloudFrontUrl}/Images/community-logo.png`;
export const ONEONEMENTORLOGO = `${cloudFrontUrl}/Images/one-one-mentor-logo.png`;
export const INVESTORLOGO = `${cloudFrontUrl}/Images/investor-logo.png`;
export const POWERUPSLOGO = `${cloudFrontUrl}/Images/powerups-logo.png`;
