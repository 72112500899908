import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { sosPrimary } from "../../../constants/Theme/colors";

const SBContainer = styled.div(({ theme }) => {
  return {
    height: "115px",
    background:
      "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "24px",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& img": {
      maxHeight: "40px",
      maxWidth: "100%",
    },
  };
});

const ServiceBox = ({ image, label }) => {
  return (
    <SBContainer>
      <img src={image} alt={label} />
    </SBContainer>
  );
};

export default ServiceBox;
