import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";
import { themeTypography } from "./typography";
import { themeButtons } from "./buttons";
import { themeLayout } from "./layout";
import { themeFlag } from "./flag";

const theme = createTheme(
  themePalette,
  themeTypography,
  themeLayout,
  themeButtons,
  themeFlag
);

export default theme;
