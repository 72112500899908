import { createTheme } from "@mui/material/styles";

export const sosPrimary = {
  900: "#492b76",
  800: "#593587",
  700: "#633b90",
  600: "#6e439a",
  500: "#7649a0",
  400: "#8963ae",
  300: "#9e7fbd",
  200: "#b9a4d0",
  100: "#d4c8e2",
  50: "#eee9f3",
};

export const themePalette = createTheme({
  palette: {
    type: "light",
    primary: {
      main: sosPrimary[500],
    },
    secondary: {
      main: "#4B454E",
      light: "#7C757F",
    },
    text: {
      primary: "#1D1B1E",
      secondary: "#4B454E",
    },
  },
});
