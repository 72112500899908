import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeTypography = createTheme(themePalette, {
  typography: {
    body1: {
      "@media (max-width:904px)": {
        fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
        fontSize: "16px",
        lineHeight: "20px",
      },
      "@media (min-width:905px)": {
        fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
        fontSize: "18px",
        lineHeight: "22px",
      },
      "@media (min-width:1440px)": {
        fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
        fontSize: "18px",
        lineHeight: "26px",
      },
    },
    h1: {
      "@media (max-width:904px)": {
        fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
        fontSize: "36px",
        lineHeight: "44px",
      },
      "@media (min-width:905px)": {
        fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        fontSize: "45px",
        lineHeight: "52px",
      },
      "@media (min-width:1440px)": {
        fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        fontSize: "57px",
        lineHeight: "64px",
      },
    },
    h2: {
      "@media (max-width:904px)": {
        fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
        fontSize: "24px",
        lineHeight: "32px",
      },
      "@media (min-width:905px)": {
        fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
        fontSize: "28px",
        lineHeight: "36px",
      },
      "@media (min-width:1440px)": {
        fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
        fontSize: "32px",
        lineHeight: "40px",
      },
    },
    h3: {
      "@media (max-width:904px)": {
        fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        fontSize: "14px",
        lineHeight: "20px",
      },
      "@media (min-width:905px)": {
        fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        fontSize: "16px",
        lineHeight: "24px",
      },
      "@media (min-width:1440px)": {
        fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        fontSize: "22px",
        lineHeight: "28px",
      },
    },
    h4: {
      "@media (max-width:904px)": {
        fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        fontSize: "11px",
        lineHeight: "16px",
      },
      "@media (min-width:905px)": {
        fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        fontSize: "12px",
        lineHeight: "16px",
      },
      "@media (min-width:1440px)": {
        fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    button: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 500,
    },
  },
});
