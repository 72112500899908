import React from 'react';
import PropTypes from 'prop-types';
import {
  PageWrapper,
  HeaderArea,
  MainArea,
  FooterArea,
  BackgroundContainer,
  BGCTop,
} from './styles';
import Footer from '../../../components/Footer';
import AppHeader from '../../../components/AppHeader';
import { Box } from '@mui/material';

export default function DefaultLayout({ children }) {
  return (
    <Box sx={{ position: 'relative' }}>
      <PageWrapper>
        <HeaderArea>
          <AppHeader />
        </HeaderArea>
        <MainArea>{children}</MainArea>
        <FooterArea>
          <Footer />
        </FooterArea>
      </PageWrapper>
      <BackgroundContainer>
        <BGCTop></BGCTop>
      </BackgroundContainer>
    </Box>
  );
}
DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
