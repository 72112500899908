import { createTheme } from "@mui/material/styles";
import { sosPrimary, themePalette } from "./colors";

export const themeButtons = createTheme(themePalette, {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        root: {
          borderRadius: 12,
          padding: "10px 24px",
          textTransform: "none",
          fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            "&:hover": {
              backgroundColor: sosPrimary[400],
              boxShadow:
                "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            },
            "&:focus": {
              backgroundColor: sosPrimary[400],
            },
            "&:disabled": {
              backgroundColor: "#1F1F1F1F",
            },
            "&:active": {
              backgroundColor: sosPrimary[400],
              boxShadow: "unset",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            borderColor: themePalette.palette.secondary.light,
            "&:hover": {
              backgroundColor: sosPrimary[50],
            },
            "&:focus": {
              backgroundColor: sosPrimary[100],
              borderColor: themePalette.palette.primary.main,
            },
            "&:disabled": {
              backgroundColor: "none",
              borderColor: "#1F1F1F1F",
            },
            "&:active": {
              color: themePalette.palette.secondary.light,
              borderColor: themePalette.palette.secondary.light,
            },
          },
        },
        {
          props: { variant: "nofill", color: "primary" },
          style: {
            color: themePalette.palette.primary.main,
            "&:hover": {
              backgroundColor: sosPrimary[50],
            },
            "&:focus": {
              backgroundColor: sosPrimary[100],
            },
            "&:disabled": {
              backgroundColor: "none",
            },
            "&:active": {
              backgroundColor: sosPrimary[200],
            },
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      variants: [
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            border: "2px solid",
            borderColor: sosPrimary[50],
            color: sosPrimary[50],
            "&:hover": {
              backgroundColor: sosPrimary[400],
              borderColor: sosPrimary[200],
            },
            "&:focus": {
              backgroundColor: sosPrimary[400],
            },
            "&:disabled": {
              backgroundColor: "#1F1F1F1F",
            },
            "&:active": {
              borderColor: sosPrimary[300],
              color: sosPrimary[100],
            },
          },
        },
      ],
    },
  },
});
