import styled from '@emotion/styled';

export const PageWrapper = styled.div(({ theme }) => {
  return {
    minHeight: '100vh',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
  };
});
export const HeaderArea = styled.div(({ theme }) => {
  return {
    [theme.breakpoints.down('md')]: {
      background: 'var(--home-blue-background)',
    },
  };
});
export const MainArea = styled.div(({ theme }) => {
  return {
    // [theme.breakpoints.down('md')]: {
    //   background: 'var(--home-blue-background)',
    // },
  };
});
export const FooterArea = styled.div(({ theme }) => {
  return {
    // [theme.breakpoints.up('md')]: {
    //   flexDirection: 'row',
    //   justifyContent: 'flex-start',
    //   alignItems: 'center',
    // },
    // [theme.breakpoints.down('md')]: {
    //   flexDirection: 'column',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    // },
  };
});

export const BackgroundContainer = styled.div(({ theme }) => {
  return {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '-1',
    height: '100%',
    width: '100%',
    background: 'var(--home-background)',
  };
});

export const BGCTop = styled.div(({ theme }) => {
  return {
    background: 'var(--home-blue-background)',
    borderRadius: '0px 0px 40px 40px',
    width: '100%',
    minHeight: '750px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  };
});
