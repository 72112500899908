import React from "react";
import { Grid, Box, Button } from "@mui/material";
import { LOGOICON } from "../../constants/image";
import styled from "@emotion/styled";
import { Flag } from "../../pages/Home/styles";

const HeaderComp = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: "8px 48px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "16px",
    },
  };
});
const HeaderContent = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: "8px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
      "& > div": {
        marginBottom: "16px",
      },
    },
  };
});

const NavigateButtons = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  };
});

const LOGO = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  };
});

export default function AppHeader(props) {
  const appUrl = process.env.REACT_APP_STARTUPOS_APP_URL;
  return (
    <header className="main-header MuiAppBar-colorPrimary">
      {/* For desktop screens */}
      <HeaderComp
        sx={{
          display: { xs: "none", md: "block" },
        }}
      >
        <Box
          sx={{
            maxWidth: "1500px",
            width: "100%",
            display: { xs: "none", md: "block" },
          }}
        >
          <HeaderContent>
            <Box
              sx={{
                maxWidth: "180px",
                width: "100%",
              }}
            ></Box>
            <LOGO>
              <Grid item xs={4}>
                <Grid container justifyContent="center">
                  <img className="cursor-pointer" src={LOGOICON} alt="logo" />
                </Grid>
              </Grid>
            </LOGO>
            <NavigateButtons>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Button
                    onClick={() => (window.location.href = `${appUrl}/login`)}
                    variant="outlined"
                  >
                    Log in
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => (window.location.href = appUrl)}>
                    Apply for Beta
                  </Button>
                </Grid>
              </Grid>
            </NavigateButtons>
          </HeaderContent>
        </Box>
      </HeaderComp>

      {/* For mobile screens */}
      <Box
        sx={{
          pt: 2,
          px: 3,
          paddingBottom: "10px",
          display: { xs: "block", md: "none" },
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            xs={7}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <img className="cursor-pointer" src={LOGOICON} alt="logo" />{" "}
            <Flag
              size="small"
              label="Beta"
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={5}>
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => (window.location.href = `${appUrl}/login`)}
              >
                Log in
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </header>
  );
}
