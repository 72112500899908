import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";

const FooterComp = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    // position: "fixed",
    // bottom: 0,
    // left: 0,
    // width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "8px 48px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "16px",
    },
  };
});
const FooterContent = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: "8px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
      "& > div": {
        marginBottom: "16px",
      },
    },
  };
});

const SocialIcons = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  };
});

const Links = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    a: {
      fontSize: "14px",
      textDecoration: "none",
      fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
      color: "white",
      "&:hover, &:focus": {
        textDecoration: "underline",
      },
    },
  };
});

export default function Footer(props) {
  return (
    <FooterComp>
      <Box sx={{ maxWidth: "1500px", width: "100%" }}>
        <FooterContent>
          <Box>
            <Typography
              variant="custom"
              sx={{
                color: "white",
                fontSize: "14px",
                fontFamily: '"RobotoRegular", "Helvetica", "Arial", sans-serif',
              }}
            >
              © 2022 StartupOS LLC. All rights reserved
            </Typography>
          </Box>
          <Links>
            <a href="/">Terms and Conditions</a>
            <a href="/">Privacy Policy</a>
          </Links>
          <SocialIcons>
            <IconButton
              aria-label="Facebook"
              variant="outlined"
              color="secondary"
            >
              <Facebook />
            </IconButton>
            <IconButton
              aria-label="Twitter"
              variant="outlined"
              color="secondary"
            >
              <Twitter />
            </IconButton>
            <IconButton
              aria-label="LinkedIn"
              variant="outlined"
              color="secondary"
            >
              <LinkedIn />
            </IconButton>
          </SocialIcons>
        </FooterContent>
      </Box>
    </FooterComp>
  );
}
