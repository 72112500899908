import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
/* 
  Uncomment and add route to view the theme demo page.
  Contains examples of text and buttons.
  Elements use MUI v5 Theme engine for configuration.
  See https://mui.com/customization/theming/ for details.
*/
// import ThemeDemo from './constants/Theme/themedemo';
// <Route path={'/demo'} exact component={ThemeDemo} />

function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
