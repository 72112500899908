import React, { Component } from "react";
import { Grid, Box, Typography, Button, Chip } from "@mui/material";
import BoxComponent from "../../common/component/serviceBox/ServiceBox";
import {
  ROADRUNNERICON,
  COMMUNITYLOGO,
  ONEONEMENTORLOGO,
  INVESTORLOGO,
  POWERUPSLOGO,
  DASHBOARDIMG,
} from "../../constants/image";
import sampleData from "../../constants/text";
import "./home.css";
import { Flag } from "./styles";
// import NumberBoxComponent from "../../common/component/countBox/CountBox";

// Todo: Load image from S3
import HEROIMAGE from "../../assets/images/dashboard-img.svg";

class Home extends Component {
  render() {
    return (
      <>
        {/* Desktop Display */}
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Grid container justifyContent="center">
            <Box
              sx={{ maxWidth: "1500px", px: 6, pb: 6 }}
              className="main-content"
            >
              {/* Margin Left -> -35px; This is to offset the Roadrunner tail at the top of the page. */}
              <Box sx={{ mt: 5, mb: 3 }}>
                <Flag label="Beta" variant="outlined" color="secondary" />
              </Box>
              <Box sx={{ mb: 3, ml: "-35px" }}>
                <img src={ROADRUNNERICON} alt="roadRunnerIcon" />
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {/* Max Width -> 700px; This is for the hero text. Width defined as ~ 700px in the prototype. */}
                  <Box sx={{ mb: 3, maxWidth: "700px" }} className="title-text">
                    <Typography variant="h1" color="primary">
                      {sampleData.landingTitle}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography>{sampleData.landingDescription}</Typography>
                  <Box sx={{ mt: 3 }}>
                    <Button
                      onClick={() =>
                        (window.location.href =
                          process.env.REACT_APP_STARTUPOS_APP_URL)
                      }
                    >
                      Apply for Beta
                    </Button>
                  </Box>
                  <Box sx={{ mt: 5 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <BoxComponent image={POWERUPSLOGO} label="PowerUPS" />
                      </Grid>
                      <Grid item xs={12}>
                        <BoxComponent
                          image={ONEONEMENTORLOGO}
                          label="1:1 Mentorship"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BoxComponent
                          image={COMMUNITYLOGO}
                          label="Exclusive Founders Community"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BoxComponent
                          image={INVESTORLOGO}
                          label="Investor Radar®"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Box>
                    <img
                      src={DASHBOARDIMG}
                      className="w-100"
                      alt="StartupOS Dashboard Preview"
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* MLP-123: Temp hide/remove the +# boxes until we have accurate data to feed in */}
              {/* 
              <Grid
                container
                spacing={2}
                className="number-box-container desk-pt-48 w-100"
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <NumberBoxComponent
                    number={40}
                    label="tools & templates and personalized task lists"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <NumberBoxComponent
                    number={5000}
                    label="investors looking for startups like yours, with the Investor Radar®"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <NumberBoxComponent number={500} label="trusted advisors" />
                </Grid>
              </Grid> 
              */}
            </Box>
          </Grid>
        </Box>

        {/* Mobile Display */}
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Box
            sx={{
              pb: 5,
              pt: 5,
              px: 2,
              background: "var(--home-blue-background)",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <img src={ROADRUNNERICON} alt="roadRunnerIcon" />
            </Box>

            <Box sx={{ mt: 3, textAlign: "center" }} className="title-text">
              <Typography variant="h1" color="primary" sx={{ width: 1 }}>
                {sampleData.landingTitle}
              </Typography>
            </Box>

            <Box sx={{ mt: 4, textAlign: "center" }}>
              <Typography
                variant="custom"
                sx={{
                  fontFamily:
                    '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
                }}
              >
                {sampleData.landingDescription}
              </Typography>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Grid container justifyContent="center">
                <img className="w-100" src={DASHBOARDIMG} alt="dashboard" />
              </Grid>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Button
                sx={{ width: 1 }}
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_STARTUPOS_APP_URL)
                }
              >
                Apply for Beta
              </Button>
            </Box>
          </Box>
          <Box sx={{ pb: 5, pt: 5, px: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BoxComponent image={POWERUPSLOGO} label="PowerUPS" />
              </Grid>
              <Grid item xs={12}>
                <BoxComponent image={ONEONEMENTORLOGO} label="1:1 Mentorship" />
              </Grid>
              <Grid item xs={12}>
                <BoxComponent
                  image={COMMUNITYLOGO}
                  label="Exclusive Founders Community"
                />
              </Grid>
              <Grid item xs={12}>
                <BoxComponent image={INVESTORLOGO} label="Investor Radar®" />
              </Grid>
            </Grid>

            {/* MLP-123: Temp hide/remove the +# boxes until we have accurate data to feed in */}
            {/* 
            <div className="mob-number-box-container mt-40 ">
              <div>
                <NumberBoxComponent
                  number={40}
                  label="tools & templates and personalized task lists"
                />
              </div>
              <div className="mt-24">
                <NumberBoxComponent
                  number={5000}
                  label="investors looking for startups like yours, with the Investor Radar®"
                />
              </div>
              <div className="mt-24 pb-84">
                <NumberBoxComponent number={500} label="trusted advisors" />
              </div>
            </div> 
            */}
          </Box>
        </Box>
      </>
    );
  }
}

export default Home;
